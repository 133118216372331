.title {
    display: flex;
    justify-content: center;
}

.Btn-bg {
    background-color: rgb(85, 1, 168) !important; 
    border-color: rgb(85, 1, 168) !important;
    margin: 5px;
}

.container-projects {
    padding-top: 15vh;
    padding-bottom: 15vh;
    
}

.card {
    height: 100%;
}