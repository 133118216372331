.about-container {
	display: flex;
    flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding-top: 15vh;
    padding-bottom: 15vh;
}

.about {
	display: flex;
    flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.profile_portrait {
	width: 250px;
	margin: 0 10px;
	border-radius: 50%;
}

