.profile-contact {
	display: flex;
	justify-content: center;
	align-items: center;
}

.contact-links {
	color: white;
	text-decoration: none;
}

